import classNames from 'classnames';
import Modal from 'components/Modal';
import { SaveIcon } from 'images/common';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { FormattedMessage } from 'react-intl';
import appMessages from 'utils/messages';

class ProcessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWarningModal: false,
    };
    this.popupRef = React.createRef();
  }

  componentDidMount() {
    document.body.style.overflowY = 'hidden';
    document.addEventListener('keydown', this.onEscClick, false);

    setTimeout(() => {
      this.setState({showPopup: true})
    }, 10)
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'scroll';
    document.removeEventListener('keydown', this.onEscClick, false);
  }

  onEscClick = (event) => {
    if (!this.props.ignoreCloseOnEsc && event.keyCode === 27) {
      // on Esc button click it should close popup
      this.props.onCancel && this.props.onCancel();
    }
  }

  handleScrollbarsMount = (scrollbars) => {
    const viewElem = scrollbars && scrollbars.view;

    this.popupRef = viewElem;
    // hack to update ref and pass down
    this.setState({});
  }

  onModalClose = () => {
    this.setState({ showWarningModal: false });
  }

  renderCancelModal = () => {
    const { onCancel } = this.props;

    const buttons = [
      <button className="modal__confirm" key="confirm" onClick={onCancel}>
        <FormattedMessage {...appMessages.confirm} />
      </button>,
      <button className="modal__cancel" key="cancel" onClick={this.onModalClose}>
        <FormattedMessage {...appMessages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={<FormattedMessage {...appMessages.discardChanges} />}
        body={<p><FormattedMessage {...appMessages.cancelMessage} /></p>}
        onClose={this.onModalClose}
      />
    );
  }

  onCancel = () => {
    const {onCancel, isChanged} = this.props
    this.setState({showPopup: false}, () => {
      if (this.props.showUnsavedWarning && isChanged) {
        return this.setState({showWarningModal: true})
      }

      setTimeout(()=> {
        onCancel()
      }, 100)
    })
  }

  render() {
    const { showWarningModal } = this.state;
    const {
      onBack,
      onCancel,
      onSave,
      onBackRight,
      theme,
      discardClass,
      discardLabel, customClass, hideVersion, scrollClass, noPadding, banner, hideInnerPopupClass } = this.props
    const wrapperClass = classNames('popup popup_visible', {
      [customClass]: customClass,
      'black-popup': theme
      // 'popup_visible': this.state.showPopup
    });

    return (
      <div id="popup-drs" className={wrapperClass}>
        {banner}
        {showWarningModal && this.renderCancelModal()}
        <Scrollbars
          autoHide
          autoHideDuration={1}
          style={{ width: '100%', height: '100vh' }}
          renderView={props => (
            <div
              className={classNames('popup-scroll-wrap', {
                [scrollClass]: scrollClass,
              })}
              {...props}
            />
          )}
          universal
          renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
          ref={this.handleScrollbarsMount}
        >
          {(onBackRight || onBack || onSave || onCancel) && (
            <nav className="head-nav">
              {
                onBackRight
                && (
                  <a
                    href="javascript:void(0)"
                    onClick={onBackRight}
                    className="round-button round-button_tr round-button_back"
                  >
                    <span className="round-button__label">
                      <FormattedMessage {...appMessages.back} />
                    </span>
                  </a>
                )
              }
              { onBack
              && (
                <a
                  href="javascript:void(0)"
                  onClick={onBack}
                  className="round-button round-button_tl round-button_back"
                >
                  <span className="round-button__label"><FormattedMessage {...appMessages.back} /></span>
                </a>
              )
              }
              {
                onSave
              && (
                <a
                  onClick={onSave}
                  className="round-button round-button_tr"
                >
                  <SaveIcon />
                  <span className="round-button__label"><FormattedMessage {...appMessages.save} /></span>
                </a>
              )
              }
              { onCancel
              && (
                <a
                  href="javascript:void(0)"
                  onClick={this.onCancel}
                  data-cy="process-button-close"
                  className={classNames('round-button round-button_tr round-button_close', {
                    'round-button_extra-top': banner,
                  })}
                >
                  <span className={classNames("round-button__label", {
                    [discardClass]: discardClass
                  })}>
                    {discardLabel || <FormattedMessage {...appMessages.cancel} />}
                  </span>
                </a>
              )
              }
            </nav>
          )
          }
          <div className={classNames('popup__cont', {
            'popup__no-padding': noPadding,
          })}
          >
            <div className={classNames('', {
              'popup__inner': !hideInnerPopupClass
            })}
            >
              {this.popupRef.current !== null && this.props.children(this.popupRef)}
            </div>
          </div>
        </Scrollbars>
        {!hideVersion && (
          <div className="viso-version">
            <div className="current-version__title h2">viso.ai</div>
          </div>
        )}
      </div>
    );
  }
}

ProcessPage.propTypes = {
  children: PropTypes.any.isRequired,
  onBack: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.any,
  onBackRight: PropTypes.any,
};

export default ProcessPage;
