import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import axios from 'axios';
import axiosObj from './config';

const DEVICE_API_URL = `${process.env.API_URL}/device/graphql`;
const RELEASE_API_URL = `${process.env.API_URL}/release/graphql`;

const VIDEO_STREAM = {
  streamId: true,
  name: true,
};

const DEVICE_LOCAL_CONFIG = {
  flows: true,
  nodes: {
    id: true,
    status: true,
    modified: true,
    updatedAt: true,
    updatedBy: {
      id: true,
      name: true,
    },
  },
  nodeVersionInfo: true
};

const DEVICE_PENDING = {
  id: true,
  name: true,
  deviceType: true,
  architecture: true,
  ipAddress: true,
  osVersion: true,
  approvalStatus: true,
  createdAt: true,
  approvedAt: true,
  createdBy: {
    id: true,
    name: true
  },
  demoData: true,
}

const DEFAULT_CONFIG_VERSIONS = {
  version: true,
  description: true,
  tags: true,
  profile: {
    id: true,
    name: true,
    application: {
      id: true,
      name: true,
    },
    applicationVersion: true,
  },
  createdBy: {
    id: true,
    name: true,
  },
  createdAt: true,
};

const DEVICE_CONTAINER = {
  containerId: true,
  nodeId: true,
  name: true,
  status: true,
  type: true,
  weight: true,
  fps: true,
  uptime: true,
  createdAt: true,
  image: true,
  cpu: true,
  memory: true,
  gpu: true,
};

const DEVICE_GROUP_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  createdAt: true,
  deviceCount: true,
  createdBy: {
    id: true,
    name: true,
  },
};

const ASSIGN_PROFILE_CHECKS = {
  newLevel: {
    id: true,
    useLocalConfig: true,
  },
  replaceLevel: {
    id: true,
    useLocalConfig: true,
  },
  updateLevel: {
    id: true,
    useLocalConfig: true,
  },
};

const DEVICE_TYPE_RETURN_VALUES = {
  name: true,
  manufacturer: true,
  version: true,
  deviceCount: true,
  updated: true,
  size: true,
  lastSeen: true,
  id: true,
  downloadUrl: true,
};

const NODE_CONFIG = {
  id: true,
  name: true,
  description: true,
  tags: true,
  moduleID: true,
  nodeType: true,
  nodeVersion: true,
  flow: true,
  profile: {
    id: true,
    name: true,
  },
  createdBy: {
    id: true,
    name: true,
  },
  updatedBy: {
    id: true,
    name: true,
  },
  createdAt: true,
  updatedAt: true,
  demoData: true
}

const DEVICE_HEALTH_REPORT = {
  checkVisoOS: true,
  checkUnderVoltage: true,
  checkMemory: true,
  checkTemperature: true,
  checkContainerEngine: true,
  checkSupervisor: true,
  checkNetwork: true,
  checkDiskspace: true,
  checkServiceRestarts: true,
  checkTimesync: true,
  checkCameraBroken: true,
  checkContainerStatus: true,
};

const DEVICES_RETURN_VALUES = {
  id: true,
  name: true,
  groups: {
    id: true,
    name: true,
  },
  lastSeen: true,
  deviceType: true,
  profile: {
    name: true,
    id: true,
    dependencies: true,
    application: {
      id: true,
      name: true,
    },
    applicationVersion: true,
  },
  localConfigVersion: true,
  tags: true,
  status: true,
  updatedAt: true,
  createdBy: {
    id: true,
    name: true,
  },
  createdAt: true,
  configStatus: true,
  ipAddress: true,
  profileDeployedAt: true,
  applicationConfiguredAt: true,
  shellhubUid: true,
  healthCheck: true,
  lastScannedAt: true,
  imagesTotal: true,
  imagesLeft: true,
  demoData: true,

};

const DEVICE_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  ipAddress: true,
  groups: {
    id: true,
    name: true,
    deviceCount: true,
  },
  profile: {
    name: true,
    id: true,
    application: {
      id: true,
      name: true,
    },
    applicationVersion: true,
    deviceCount: true,
    createdAt: true,
    dependencies: true,
  },
  shellhubUid: true,
  deviceType: true,
  status: true,
  createdBy: {
    id: true,
    name: true,
  },
  osVersion: true,
  osStatus: true,
  revertedOSVersion: true,
  latestOSVersion: true,
  lastSeen: true,
  lastScannedAt: true,
  createdAt: true,
  updatedAt: true,
  profileDeployedAt: true,
  profileDeployedBy: {
    id: true,
    name: true,
  },
  applicationConfiguredAt: true,
  applicationConfiguredBy: {
    id: true,
    name: true,
  },
  healthCheck: true,
  configStatus: true,
  localConfigVersion: true,
  username: true,
  secret: true,
  imagesTotal: true,
  imagesLeft: true,
  visoImage: true
};

const BULK_ACTION = {
  message: true,
  skippedCount: true,
};

export async function getModelListPending(isLatestODVersion) {
  let MODEL_URL = 'https://viso-public-storage.s3.eu-central-1.amazonaws.com/MODEL_LIST/od_models.json';

  if (isLatestODVersion) {
    MODEL_URL = 'https://viso-public-storage.s3.eu-central-1.amazonaws.com/MODEL_LIST/od_models_v2.7.3.json';
  }

  const result = await axios.get(MODEL_URL);

  return result;
}

export async function getCocoData() {
  const result = await axios.get('https://viso-public-storage.s3.eu-central-1.amazonaws.com/MODEL_LIST/od_coco_labels.txt');

  return result;
}

export async function getReIdModel() {
  const result = await axios.get('https://viso-public-storage.s3.eu-central-1.amazonaws.com/MODEL_LIST/reid_obj_trk_models.json')

  return result;
}

export async function getOidData() {
  const result = await axios.get('https://viso-public-storage.s3.eu-central-1.amazonaws.com/MODEL_LIST/od_oid_labels.txt');

  return result;
}

export async function getYoloData() {
  const result = await axios.get('https://viso-public-storage.s3.eu-central-1.amazonaws.com/MODEL_LIST/od_yolo_labels.txt');

  return result;
}

export function getDeviceTypes(args = { limit: 5, offset: 0 }) {
  const query = {
    query: {
      images: {
        __args: args,
        count: true,
        data: DEVICE_TYPE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function assignProfileChecks(args) {
  const query = {
    query: {
      assignProfileChecks: {
        __args: args,
        ...ASSIGN_PROFILE_CHECKS,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deviceContainers(args) {
  const query = {
    query: {
      deviceContainers: {
        __args: args,
        ...DEVICE_CONTAINER,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deviceHealthReport(args) {
  const query = {
    query: {
      deviceHealthReport: {
        __args: args,
        ...DEVICE_HEALTH_REPORT,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function restartDeviceContainer(param) {
  const query = {
    mutation: {
      restartDeviceContainer: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateDeviceContainerWeight(param) {
  const query = {
    mutation: {
      updateDeviceContainerWeight: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getAllDevices(args) {
  const query = {
    query: {
      devices: {
        __args: args,
        count: true,
        data: DEVICES_RETURN_VALUES,
        hasPending: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getDevice(id) {
  const query = {
    query: {
      device: {
        __args: {
          id,
        },
        ...DEVICE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function addNewDevice(param) {
  const query = {
    mutation: {
      createDevice: {
        __args: param,
        id: true,
        name: true,
        status: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateDevice(param) {
  const query = {
    mutation: {
      updateDevice: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateDevices(param) {
  const query = {
    mutation: {
      updateDevices: {
        __args: param,
        data: {
          id: true,
          name: true,
          profile: {
            id: true,
            name: true,
          },
        },
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function removeDevice(param) {
  const query = {
    mutation: {
      archiveDevice: {
        __args: param,
        name: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createDeviceGroup(param) {
  const query = {
    mutation: {
      createGroup: {
        __args: param,
        id: true,
        name: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function assignDeviceGroupRequest(param) {
  const query = {
    mutation: {
      assignDeviceGroup: {
        __args: param,
        ...DEVICE_GROUP_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateDeviceGroup(param) {
  const query = {
    mutation: {
      updateGroup: {
        __args: param,
        ...DEVICE_GROUP_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function listDeviceGroup(param) {
  const query = {
    query: {
      groups: {
        __args: param,
        count: true,
        data: DEVICE_GROUP_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getDeviceGroup(param) {
  const query = {
    query: {
      group: {
        __args: param,
        ...DEVICE_GROUP_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getProfileDevices(param) {
  const query = {
    query: {
      devices: {
        __args: param,
        count: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteDeviceGroup(param) {
  const query = {
    mutation: {
      deleteGroup: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function rebootDevice(param) {
  const query = {
    mutation: {
      rebootDevice: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function syncBalenaDevices(params) {
  const query = {
    mutation: {
      syncBalenaDevices: {
        __args: params,
        data: {
          id: true,
        },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteDeviceById(param) {
  const query = {
    mutation: {
      deleteDevice: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteDeviceByIds(param) {
  const query = {
    mutation: {
      deleteDevices: {
        __args: param,
        ...BULK_ACTION,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getDeviceLocalFlows(param) {
  const query = {
    query: {
      deviceLocalConfig: {
        __args: param,
        ...DEVICE_LOCAL_CONFIG,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateLocalFLows(param) {
  const query = {
    mutation: {
      updateDeviceLocalFlowsNode: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deviceLocalConfigVersions(param) {
  const query = {
    query: {
      deviceLocalConfigVersions: {
        __args: param,
        ...DEFAULT_CONFIG_VERSIONS,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deployLocalConfigChanges(param) {
  const query = {
    mutation: {
      deployLocalConfigChanges: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function discardLocalConfigChanges(param) {
  const query = {
    mutation: {
      discardLocalConfigChanges: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function videoStreamURL(param) {
  const query = {
    query: {
      videoStreamURL: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function videoStreams(param) {
  const query = {
    query: {
      videoStreams: {
        __args: param,
        ...VIDEO_STREAM,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function startStreaming(param) {
  const query = {
    mutation: {
      startStreaming: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function applyLocalConfigVersion(param) {
  const query = {
    mutation: {
      applyLocalConfigVersion: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deployLocalConfigVersion(param) {
  const query = {
    mutation: {
      deployLocalConfigVersion: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export async function getGKDModel() {
  let MODEL_URL = 'https://viso-public-storage.s3.eu-central-1.amazonaws.com/MODEL_LIST/gkd_models_v3.1.json';

  const result = await axios.get(MODEL_URL);

  return result;
}

export async function getOSModel() {
  let MODEL_URL = 'https://viso-public-storage.s3.eu-central-1.amazonaws.com/MODEL_LIST/os_models.json';

  const result = await axios.get(MODEL_URL);

  return result;
}

export function shutdownDevice(param) {
  const query = {
    mutation: {
      shutdownDevice: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function saveNodeConfig(param) {
  const query = {
    mutation: {
      saveNodeConfig: {
        __args: param,
        id: true
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function nodeConfig(param) {
  const query = {
    query: {
      nodeConfig: {
        __args: param,
        id: true
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function nodeConfigs(param) {
  const query = {
    query: {
      nodeConfigs: {
        __args: param,
        ...NODE_CONFIG
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateNodeConfig(param) {
  const query = {
    mutation: {
      updateNodeConfig: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteNodeConfig(param) {
  const query = {
    mutation: {
      deleteNodeConfig: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function applyNodeConfig(param) {
  const query = {
    mutation: {
      applyNodeConfig: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function getPendingDevices(param) {
  const query = {
    query: {
      pendingDevices: {
        __args: param,
        ...DEVICE_PENDING,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });

  return devices;
  
}


export function rejectPendingDevice(param) {
  const query = {
    mutation: {
      rejectPendingDevice: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
  
}


export function approvePendingDevice(param) {
  const query = {
    mutation: {
      approvePendingDevice: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
  
}

export function approvePendingDevices(param) {
  const query = {
    mutation: {
      approvePendingDevices: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
  
}

export function rejectPendingDevices(param) {
  const query = {
    mutation: {
      rejectPendingDevices: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
  
}

export function updateOSImage(param) {
  const query = {
    mutation: {
      updateOSImage: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: DEVICE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
  
}