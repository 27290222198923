import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Circle } from 'react-feather';
import { CirclePlusIcon, TickIcon, ErrorIcon, ProgressIcon } from 'images/common';
import { FormattedMessage } from 'react-intl';
import { getInitials, isArrayEqual } from 'utils/common';
import NotFound from 'containers/NotFoundPage';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import messages from 'utils/messages';
import { makeSelectMe } from 'containers/Settings/User/selectors';
import TableHead from './TableHead';
import TableSubHead from './TableSubHead'

const nodeIcon = 'fas fa-paper-plane';

const objectIcon = {
  'region-of-interest': 'fas fa-regular fa-bookmark',
  'detection-tracking': 'fas fa-regular fa-bookmark',
  'roi': 'fas fa-regular fa-bookmark',
  'video-processing': 'fas fa-regular fa-bookmark',
  'video-feed': 'fas fa-video-camera',
  'video-source': 'fas fa-video-camera',
  'abandoned-detection': 'fas fa-binoculars',
  'fish-eye': 'fas fa-paper-plane',
  'multi-object-detection': 'fas fa-object-group;',
  'object-recognition': 'fas fa-object-group;',
  'object-tracking': 'fas fa-object-group;',
  'posture-recognition': 'fas fa-object-group;',
  'group-keypoint-detection': 'fas fa-object-group;',
  'image-classification': 'fas fa-object-group;',
  'reid-object-tracking': 'fas fa-object-group;',
  'face-detection': 'fas fa-user-circle',
  'deepface': 'fas fa-user-circle',
  'face-analysis': 'fas fa-user-circle',
  'face-recognition': 'fas fa-user-circle',
  'video-view': 'fas fa-desktop',
  'frame-viewer': 'fas fa-desktop',
  'data-collection': 'fas fa-registered',
  '3d-keypoint-detection': 'fas fa-user',
  'fall-detection': 'fas fa-user',
  'object-count': 'fas fa-user',
  'reboot': 'fas fa-user',
  'object-flow': 'fas fa-shopping-basket',
  'optical-flow': 'fas fa-shopping-basket',
  'barcode decoder': 'fas fa-barcode',
  'barcode generator': 'fas fa-barcode',
  'delivery-van': 'fas fa-truck',
  'alpr': 'fas fa-car',
  'color-recognition': 'fas fa-adjust',
  'image-feed': 'fas fa-image',
  'object-segmentation': 'fas fa-cubes',
  'snapshot': 'fas fa-file-video-o'
}

class Table extends Component {
  constructor(props) {
    super(props);

    const initialColumns = props.columns.map((column) => {
      if (column.isOptional) {
        return { ...column, isVisible: false };
      }

      return { ...column, isVisible: true };
    });

    this.state = {
      columns: initialColumns,
      columns1: this.props.columns1,
      settingSelected: null,
      isFirstTime: true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    if (window.innerWidth <= 1200) {
      const initialColumns = this.props.columns
        .map((column, index) => {
          if (column.isOptional) {
            return { ...column, isVisible: false };
          }

          if (index < 4) {
            return { ...column, isVisible: true };
          }
          return { ...column, isVisible: false };
        });

      this.onColumnsChange(initialColumns);
    }

    if (window.innerWidth <= 1050) {
      const initialColumns = this.props.columns
        .map((column, index) => {
          if (column.isOptional) {
            return { ...column, isVisible: false };
          }

          if (index < 2) {
            return { ...column, isVisible: true };
          }
          return { ...column, isVisible: false };
        });

      this.onColumnsChange(initialColumns);
    }

    if (window.innerWidth > 1200) {
      const initialColumns = this.props.columns.map((column) => {
        if (column.isOptional) {
          return { ...column, isVisible: false };
        }

        return { ...column, isVisible: true };
      });

      this.onColumnsChange(initialColumns);
    }
  };

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    if (!isArrayEqual(this.props.columns, nextProps.columns)) {
      const initialColumns = nextProps.columns.map((column) => {
        if (column.isOptional) {
          return { ...column, isVisible: false };
        }

        return { ...column, isVisible: true };
      });

      this.setState({
        columns: initialColumns,
        settingSelected: null,
      });
    }
  }

  onSelectSetting = (id) => {
    this.setState({ isFirstTime: false }, () => {
      this.setState({ settingSelected: id });
    });
  };

  onClearSelectedSetting = () => {
    this.setState({ isFirstTime: false }, () => {
      this.setState({ settingSelected: null });
    });
  }

  renderRows() {
    const {
      rows,
      rowKey,
      disabled,
      inputType,
      rowClassName,
      hideSingleMenu,
      rowClick,
      hasImg,
      isChecked,
      multipleSelect,
      isSelectable,
      preventRedirectOnClick,
      RowComponent,
      onPushProfile,
      onShowDeleteModal,
      onAssignProfile,
      isUserSetting,
      isLogoSetting,
      isPriceSetting,
      monthlyPriceId,
      yearlyPriceId,
      isRolePage,
      isNodeType,
      selectedItems = [],
    } = this.props;
    const { isFirstTime } = this.state;

    return rows
      .sort(
        (row1, row2) => isFirstTime
          && selectedItems.includes(row2.id) - selectedItems.includes(row1.id),
      )
      .map((row, index) => (
        <tr
          key={rowKey ? row[rowKey] : index}
          onClick={(e) => {
            if (multipleSelect && preventRedirectOnClick) {
              return this.toggleSelect(row, e);
            }

            if (preventRedirectOnClick) {
              return this.toggleSelect(row, e);
            }

            if(!row.demoData) {
              rowClick(row.id, e);
            }
          }}
          className={classNames('data-table__row', rowClassName, {
            isActive: this.state.settingSelected === row.id,
            'disabled-event': ((isRolePage && (row.name !== 'Owner' && (row.name !== 'Viso Support')) && disabled) || (!isRolePage && disabled)),
            'demo-data-event': row.demoData,
            'data-table__row_disabled': isSelectable && this.isDisabled(row),
            'data-table__row_selected': isSelectable && this.isSelected(row),
            [`data-table__row_${this.props.colorClassName}`]: this.props
              .colorClassName,
          })}
        >
          {isUserSetting && (
            <td className="data-table__td">
              <div className={`checkbox checkbox_${this.props.colorClassName}`}>
                <input
                  type="checkbox"
                  className="checkbox__input"
                  checked={this.isSelected(row)}
                  readOnly
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ isFirstTime: false });
                    this.toggleSelect(row, e);
                  }}
                />
                <div className="checkbox__image">
                  {getInitials(row.firstName, row.lastName)}
                </div>
              </div>
            </td>
          )}

          {isPriceSetting && (
            <>
              <td className="data-table__td">
                <div
                  className={`checkbox checkbox_${this.props.colorClassName}`}
                >
                  <input
                    type="checkbox"
                    className="checkbox__input"
                    checked={this.isSelected(row)}
                    readOnly
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ isFirstTime: false });
                      this.toggleSelect(row, e);
                    }}
                  />
                  <div className="checkbox__image">
                    {this.props.getCurrencyIcon[row.currency]
                      || row.currency.toUpperCase()}
                  </div>
                </div>
                {monthlyPriceId === row.id && (
                  <span
                    className={`data-cards__selected table-icon_ticker ${this.props.colorClassName}`}
                  >
                    <TickIcon />
                  </span>
                )}
                {yearlyPriceId === row.id && (
                  <span
                    className={`data-cards__selected table-icon_ticker ${this.props.colorClassName}`}
                  >
                    <TickIcon />
                  </span>
                )}
              </td>
            </>
          )}

          {!isNodeType && !isLogoSetting && !isUserSetting && isSelectable && !isPriceSetting && (
            <td className="data-table__td">
              <div
                className={classNames(
                  'checkbox',
                  this.props.checkboxClassName,
                  {
                    [`checkbox_${this.props.colorClassName}`]: this.props
                      .colorClassName,
                  },
                )}
                onClick={(ev) => {
                  this.setState({ isFirstTime: false });
                  ev.stopPropagation();
                  this.toggleSelect(row, ev);
                }}
              >
                {inputType === 'radio' ? (
                  <div className="radio">
                    <input
                      type="radio"
                      name="row"
                      className="radio__input"
                      checked={this.isSelected(row)}
                    />
                    <div className="radio__check" />
                  </div>
                ) : (
                  <>
                    <input
                      checked={this.isSelected(row)}
                      type="checkbox"
                      className="checkbox__input"
                      readOnly
                    />
                    {(row.isNew || row.isNewTemplate) && <div className="card__new"><FormattedMessage {...messages.new} /></div>}
                    {(isChecked && !row.isNewTemplate) && (
                      <span
                        className={`data-cards__plus ${this.props.colorClassName
                          }`}
                      >
                        <CirclePlusIcon />
                      </span>
                    )}
                    <div className={classNames('checkbox__image', { 'no-border-only': hasImg })}>
                      {hasImg ? (
                        this.props.CheckboxImage
                      ) : (
                        <this.props.CheckboxImage row={row} />
                      )}
                    </div>

                  </>
                )}
              </div>
              {row.healthCheck === false && row.status !== 'os_updating' && (
                <span
                  className={`data-cards__selected ${this.props.colorClassName} table-icon_ticker failure`}
                >
                  <ErrorIcon />
                </span>
              )}
              {row.status === 'os_updating' && (
                <span
                  className={`data-cards__selected ${this.props.colorClassName} table-icon_ticker failure`}
                >
                  <ProgressIcon />
                </span>
              )}
            </td>
          )}

          {isLogoSetting && (
            <td className="data-table__td">
              <div className={`checkbox checkbox_${this.props.colorClassName}`}>
                <input
                  type="checkbox"
                  className="checkbox__input"
                  checked={this.isSelected(row)}
                  readOnly
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ isFirstTime: false });
                    this.toggleSelect(row, e);
                  }}
                />
                <div className={`${hasImg && 'no-border-only'} checkbox__image`}>
                  <img src={row.logo || 'http://placehold.it/88x88'} alt="Img" width="52px" height="52px" />
                </div>
              </div>
            </td>
          )}

          {isNodeType && (
            <td className="data-table__td">
              <div className={`checkbox checkbox_${this.props.colorClassName} checkbox-node-accent3`}>
                <input
                  type="checkbox"
                  className="checkbox__input"
                  checked={this.isSelected(row)}
                  readOnly
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ isFirstTime: false });
                    this.toggleSelect(row, e);
                  }}
                />
                <div className={`${hasImg && 'no-border-only'} checkbox__image checkbox-node`}>
                  <i className={objectIcon[row.type] || nodeIcon} width="52px" height="52px"></i>
                </div>
                {row.configModified &&
                  <span
                    className={`table-icon_node ${this.props.colorClassName}`}
                  >
                    <Circle
                      size={2}
                      fill="#FFF"
                      stroke="#FF7A7A"
                    />
                  </span>
                }
              </div>
            </td>
          )}

          <RowComponent
            {...row}
            {...this.props}
            settingSelected={this.state.settingSelected}
            onSelectSetting={this.onSelectSetting}
            onClearSelectedSetting={this.onClearSelectedSetting}
            hideSingleMenu={hideSingleMenu}
            onShowDeleteModal={onShowDeleteModal}
            {...this.props.rowProps}
            onPushProfile={onPushProfile}
            onAssignProfile={onAssignProfile}
            columns={this.state.columns}
            colorClassName={this.props.colorClassName}
            index={index}
          />
        </tr>
      ));
  }

  renderLoading() {
    const {
      rowClassName,
    } = this.props;

    return [1, 2, 3, 4, 5, 6, 7, 8]
      .map((row, index) => (
        <tr
          key={index}
          className={classNames('data-table__row', rowClassName, {
          })}
        >
          <td className="data-table__td">
            <div className={`checkbox checkbox_${this.props.colorClassName}`}>
              <input
                type="checkbox"
                className="checkbox__input"
                checked={this.isSelected(row)}
                readOnly
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ isFirstTime: false });
                }}
              />
              <div className="checkbox__image">
              </div>
            </div>
          </td>
          <td className={classNames('data-table__td')} />
          <td className={classNames('data-table__td')} />
          <td className={classNames('data-table__td')} />
          <td className={classNames('data-table__td')} />
          <td className={classNames('data-table__td')} />
          <td className={classNames('data-table__td')} />
        </tr>
      ));
  }

  onColumnsChange = (columns) => {
    this.setState({ columns });
  };

  renderEmptyComponent = () => {
    if (this.props.EmptyRowComponent) {
      return this.props.EmptyRowComponent();
    }

    return (
      <NotFound
        parentClass="no-result__container"
        color={this.props.colorClassName}
        strokeWidth="1"
        logo={this.props.notFoundIcon}
        title={<FormattedMessage {...messages.noResultsFound} />}
        description={<FormattedMessage {...messages.tryAdjustingSearch} />}
      />
    );
  };

  renderTableBody = () => {
    const { allowOnlySingleSelect, inputType } = this.props;

    return (
      <>
        <table
          className={classNames('data-table__table', this.props.tableClassName)}
        >
          {this.props.columnsSubHead && <TableSubHead
            inputType={this.props.inputType}
            onUpdateSort={this.props.onUpdateSort}
            currentSortDir={this.props.currentSortDir}
            currentSortKey={this.props.currentSortKey}
            columns={this.props.isLoading
              ?
              [
                { isVisible: true },
                { isVisible: true },
                { isVisible: true },
                { isVisible: true },
                { isVisible: true },
              ]
              :
              this.props.columnsSubHead}
            isSelectable={this.props.isSelectable}
            isSelectedAll={
              this.props.isSelectable ? this.isSelectedAll() : false
            }
            selectAll={this.selectAll}
            hideCheckbox={allowOnlySingleSelect || inputType === 'radio'}
            colorClassName={this.props.colorClassName}
            withColumnSelect={this.props.withColumnSelect}
            onColumnsChange={this.onColumnsChange}
          />
          }
          <TableHead
            hasSubHead={this.props.columnsSubHead}
            inputType={this.props.inputType}
            onUpdateSort={this.props.onUpdateSort}
            currentSortDir={this.props.currentSortDir}
            currentSortKey={this.props.currentSortKey}
            columns={this.props.isLoading
              ?
              [
                { isVisible: true },
                { isVisible: true },
                { isVisible: true },
                { isVisible: true },
                { isVisible: true },
              ]
              :
              this
                .state.columns}
            isSelectable={this.props.isSelectable}
            isSelectedAll={
              this.props.isSelectable ? this.isSelectedAll() : false
            }
            selectAll={this.selectAll}
            hideCheckbox={allowOnlySingleSelect || inputType === 'radio'}
            colorClassName={this.props.colorClassName}
            withColumnSelect={this.props.withColumnSelect}
            onColumnsChange={this.onColumnsChange}
          />
          <tbody className="data-table__body">
            {!!this.props.rows.length && !this.props.isLoading && this.renderRows()}
            {this.props.isLoading && this.renderLoading()}
            {!this.props.rows.length && !this.props.isLoading && (
              <tr className="data-table__row">
                <td className="data-table__td_empty" />
              </tr>
            )}
          </tbody>
        </table>
        {!this.props.rows.length
          && !this.props.isLoading
          && this.renderEmptyComponent()}
      </>
    );
  };

  isSelectedAll = () => {
    const { disabledIds = [] } = this.props;

    if (this.props.rows && !this.props.rows.length) {
      return false;
    }

    return (
      (this.props.selectedItems && this.props.selectedItems.length)
      + (disabledIds && disabledIds.length)
      === (this.props.rows && this.props.rows.length)
    );
  };

  isSelected = (row) => {
    const { selectedItems = [] } = this.props;
    if (this.state.isFirstTime) {
      // this.setState({ isFirstTime: false });
    }

    return selectedItems.indexOf(row.id) > -1;
  };

  isDisabled = (row) => {
    if (row) {
      const { disabledIds = [] } = this.props;

      return disabledIds && disabledIds.indexOf(row.id) > -1;
    }
  };

  selectAll = (e) => {
    const { disabledIds = [] } = this.props;
    this.setState({ isFirstTime: false });

    if (this.isSelectedAll()) {
      this.props.onDeselectAll && this.props.onDeselectAll();
      return this.props.onSelect([], e);
    }

    const allSelected = this.props.rows
      .filter(row => !disabledIds.includes(row.id))
      .map(row => row.id);

    this.props.onSelect(allSelected, e);
    this.props.onSelectAll && this.props.onSelectAll();
  };

  toggleSelect = (row, e) => {
    const {
      onSelect,
      selectedItems = [],
      inputType,
      allowOnlySingleSelect,
    } = this.props;

    this.setState({ isFirstTime: false });

    if (this.isDisabled(row)) {
      return false;
    }

    if (allowOnlySingleSelect) {
      return onSelect(row.id, e);
    }
    if (inputType === 'radio') {
      return onSelect(row.id, e);
    }
    if (this.isSelected(row)) {
      onSelect(selectedItems.filter(item => item !== row.id), e);
    } else {
      onSelect(selectedItems.concat(row.id), e);
    }
  };

  render() {
    // if (!this.props.hideLoader && this.props.isLoading) {
    //   return (
    //     <LoadingIndicator
    //       customClass="loading-table"
    //       color={this.props.colorClassName}
    //       isProcessPage={this.props.isProcessPage}
    //     />
    //   );
    // }

    return (
      <div className={classNames('', {
        'data-table__loading': !this.props.hideLoader && this.props.isLoading,
        'data-table': !this.props.isLoading,
      })}
      >
        {this.renderTableBody()}
      </div>
    );
  }
}

Table.propTypes = {
  isUserSetting: PropTypes.bool,
  inputType: PropTypes.string,
  columns: PropTypes.array.isRequired,
  currentSortKey: PropTypes.string,
  currentSortDir: PropTypes.string,
  EmptyRowComponent: PropTypes.func,
  RowComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  CheckboxImage: PropTypes.func,
  rowClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  rowClick: PropTypes.func,
  rowKey: PropTypes.string,
  rows: PropTypes.array.isRequired,
  rowProps: PropTypes.object,
  tableClassName: PropTypes.string,
  onUpdateSort: PropTypes.func,
  isSelectable: PropTypes.bool,
  selectedItems: PropTypes.array,
  onSelect: PropTypes.func,
  onPushProfile: PropTypes.func,
  onAssignProfile: PropTypes.func,
  selectAllClass: PropTypes.string,
  colorClassName: PropTypes.string,
  withColumnSelect: PropTypes.bool,
};

Table.defaultProps = {
  onUpdateSort: f => f,
  rowClick: f => f,
};

const mapStateToProps = createStructuredSelector({
  me: makeSelectMe(),
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(
  withConnect,
)(Table);
