export const LOGOUT = 'TK_PORTAL/APP/LOGOUT';
export const LOGGED_IN_USER_DATA_REQUEST = 'TK_PORTAL/APP/LOGGED_IN_USER_DATA_REQUEST';
export const LOGGED_IN_USER_DATA_SUCCESS = 'TK_PORTAL/APP/LOGGED_IN_USER_DATA_SUCCESS';
export const LOADING = 'TK_PORTAL/APP/LOADING';
export const UPDATE_DEVICE_TYPE = 'UPDATE_DEVICE_TYPE';

export const DISPLAY_NOTIFICATION_BAR = 'DISPLAY_NOTIFICATION_BAR';
export const DISPLAY_FULL_SIDEBAR = 'DISPLAY_FULL_SIDEBAR';
export const DISPLAY_INFRA_BANNER = 'DISPLAY_INFRA_BANNER';
export const FIRST_TIME_LOGGED_IN = 'FIRST_TIME_LOGGED_IN';
export const UPDATE_SOCKET_ID = 'UPDATE_SOCKET_ID';

export const CLICK_DELETE = 'PROP/DELETE';
export const CLICK_EDIT = 'PROP/EDIT';
export const CLICK_CREATE = 'PROP/CREATE';
export const DISPLAY_FULL_DATASET_SIDEBAR = 'DISPLAY_FULL_DATASET_SIDEBAR';

export const UPLOAD_TRACKER = 'UPLOAD_TRACKER'

export const permissionNames = {
  workspace_update: 'Workspace Update',
  user_invite: 'Invite User',
  user_list: 'List User',
  user_deactivate: 'User Deactivate',
  user_update_own: 'Update Profile',
  user_update_any: 'Update Other User',
  role_create: 'Create Role',
  role_update: 'Update Role',
  role_list: 'List Role',
  role_delete: 'Delete Role',
  scope_create: 'Create Scope',
  scope_list_own: 'List Own Scope',
  scope_list_any: 'List Any Scope',
  scope_update_own: 'Update Own Scope',
  scope_update_any: 'Update Any Scope',
  scope_delete_own: 'Delete Own Scope',
  scope_delete_any: 'Delete Any Scope',
  user_group_create: 'Create User Group',
  user_group_update: 'Update User Group',
  user_group_list: 'List User Group',
  user_group_delete: 'Delete User Group',
  device_group_create: 'Create Device Group',
  device_group_update: 'Update Device Group',
  device_group_list: 'List Device Group',
  device_group_delete: 'Delete Device Group',
  device_create: 'Create Device',
  device_update_own: 'Update Own Devices',
  device_update_any: 'Update Any Devices',
  device_list: 'List Device',
  device_archive: 'Archive Device',
  release_create: 'Create Release',
  release_execute: 'Execute Release',
  release_list: 'List Release',
  report_create: 'Create Report',
  report_update_own: 'Update Own Report',
  report_update_any: 'Update Any Report',
  report_list_own: 'List Own Report',
  report_list_any: 'List Any Report',
  report_archive_own: 'Archive Own Report',
  report_archive_any: 'Archive Any Report',
  analytics_dashboard: 'Analytics Dashboard',
};
