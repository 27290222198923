import localForage from 'localforage';
import moment from 'moment';
import {
  loggedInUserDataRequest, updateInfraBanner, updateSidebarDisplay, addModelImage
} from 'containers/App/actions';
import {
  getSidebarStatus, makeSelectDevice, makeSelectLoading, makeSelectUser,
} from 'containers/App/selectors';
import { getBillingDetails } from 'containers/Billing/selectors';
import CentralPanel from 'containers/CentralPanel';
import LanguageProvider from 'containers/LanguageProvider';
import { intl } from 'containers/LanguageProvider/intlProvider';
import LeftPanel from 'containers/LeftPanel';
import { libraryModulesListRequest, onLoadingParams, getDatasetListRequest } from 'containers/Library/actions';
import libraryReducer from 'containers/Library/reducer';
import librarySaga from 'containers/Library/saga';
import MobileNav from 'containers/MobileNav';
import Notification from 'containers/Notification';
import { detailRoleRequest } from 'containers/Settings/AccessRole/actions';
import roleReducer from 'containers/Settings/AccessRole/reducer';
import roleSaga from 'containers/Settings/AccessRole/saga';
import historyReducer from 'containers/Settings/Audit/reducer';
import historySaga from 'containers/Settings/Audit/saga';
import userReducer from 'containers/Settings/User/reducer';
import userSaga from 'containers/Settings/User/saga';
import { makeSelectMe } from 'containers/Settings/User/selectors';
import { fetchWorkspaceRequest, getSubscriptionBillingPending } from 'containers/Settings/Workspace/actions';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import { getNotifications } from 'containers/Notification/selectors';
import { defaultNotifier, marketplaceNotifier } from 'functions/notificationHandler';
import { EnumType } from 'json-to-graphql-query';
import { isNil, omitBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import slugify from 'react-slugify';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { pageDoesNotRequireLogin } from 'routes';
import { socket } from 'socket';
import { APP_AUTO_RELOAD, DEFAULT_PAGE_SIZE } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from 'utils/messages';
import { getNotificationList } from 'containers/Notification/actions';
import notificationSaga from 'containers/Notification/saga';
import notificationReducer from 'containers/Notification/reducer';
import { getDatasetDetailRequest } from "containers/Library/actions";
import { getLibraryDatasetList } from "containers/Library/selectors";


import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import { getFiftyoneStatus, getNRStatus } from 'api/auth'
import { translationMessages } from '../../i18n';
import reducer from './reducer';
import saga from './saga';
import Banner from 'components/Banner';
import { announcement } from 'api/admin'
import { AlertTriangleIcon, InfoCircle, PaymentIcon } from 'images/common';
import {withRouter} from 'withRouter';
import {
  libraryAnnotationTaskRequest
} from '../Library/actions';

const marketplaceMessages = {
  module_install_success: 'isNowInstalled',
  module_update_success: 'moduleUpdated',
  module_install_fail: 'marketplaceInstallFailed',
  module_update_fail: 'moduleUpdateFailed',
  module_remove_success: 'marketplaceRemoved',
  module_remove_fail: 'failedToRemoveModule',
};

const moduleMessages = {
  module_install_success: 'moduleInstalled',
  module_update_success: 'moduleUpdated',
  module_install_fail: 'moduleInstallFailed',
  module_update_fail: 'moduleUpdateFailed',
  module_remove_success: 'moduleRemoved',
  module_remove_fail: 'failedToRemoveModule',
};

export class App extends React.PureComponent {
  state = {
    sort: {
      key: 'name',
      direction: 'asc',
    },
    filter: {
      single: [],
      range: [],
    },
    showBanner: false,
    showModal: true
  }

  onCloseBanner = async () => {
    const { announcement } = this.state;

    if (announcement.canClose) {
      this.setState({ showBanner: false });
      await localForage.setItem('hideMaintenanceBanner', true);
    }
  }

  static propTypes = {
    user: PropTypes.object,
  };

  getTitle = (isDatasetPage, isAppPage) => {
    if (isDatasetPage) {
      return 'Viso Datasets'
    }

    if (isAppPage) {
      return 'Viso Builder'
    }
  }

  getModules = () => {
    const { filter } = this.state;
    const {workspace} = this.props;

    const payload = {
      range: this.state.filter.range,
      single: this.state.searchName
        ? [...this.state.filter.single, this.state.search]
        : [...this.state.filter.single],
    };

    const params = {
      includeDemoData: workspace.demoModeEnabled
      // limit: DEFAULT_PAGE_SIZE,
      // offset: this.state.offset,
      // query: payload,
      // sort: {
      //   field: this.state.sort.key,
      //   type:
      //     this.state.sort.direction === 'asc'
      //       ? new EnumType('ASC')
      //       : new EnumType('DESC'),
      // },
      // filters: filter && !!filter.length ? filter : null,
    };

    this.props.libraryModulesListRequest(omitBy(params, isNil));
  };

  getAnnotationTask = (loading) => {
    const {workspace} = this.props;

    this.props.libraryAnnotationTaskRequest({
      limit: 200,
      includeDemoData: workspace.demoModeEnabled,
    })
  };

  async componentDidUpdate(prevProps) {
    const { workspace, user, me, router } = this.props;
    const { workspace: prevWorkspace } = prevProps;

    const pathName = router.location.pathname;

    const isDatasetPage = pathName.includes('/dataset');

    if (workspace && ((!prevWorkspace && workspace) || (prevWorkspace && (prevWorkspace.id !== workspace.id)))) {
      const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
      const oldLink = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = workspace.logo || '/favicon.jpg';

      document.getElementsByTagName('head')[0].replaceChild(link, oldLink);
      document.title = `Viso Suite - ${workspace.name}`;

      if (user && user.activeWorkspace && !workspace.builderInstalled) {
        this.setTimeInterval(user.activeWorkspace);
      }

      if (workspace && workspace.collapseSideBar) {
        this.props.updateSidebarDisplay(workspace.collapseSideBar);
      }

      if (workspace && workspace.builderInstalled) {
        clearInterval(this.state.timeInterval);
      }

      if (me) {
        const { announcement } = this.state;
        const isNew = await localForage.getItem('hideMaintenanceBanner');

        if (announcement) {
          const todayDate = moment.utc().format();

          const endDate = announcement.end
          const diffTime = moment(endDate).diff(todayDate, 'seconds') > 0;

          this.setState({ showBanner: !isNew && announcement && announcement.active && diffTime });
        }

        if (this.state.announcement && this.state.announcement.forAdmin && me && me.role && me.role.name !== "Owner" && me.role.name !== "Admin") {
          this.setState({ showBanner: false })
        }

        if (workspace) {
          try {
            await getNRStatus(workspace.id);
          }
          catch (err) {
            this.setState({ isNRInstanceNotRunning: true })
          }
        }
      }

      socket.close()
      socket.connect()
      socket.on('connect', () => {
        console.log('Connected');
        const sessionID = socket.id; // Accessing session ID
        // this.props.updateSocketId(sessionID)
        console.log('Socket ID:', sessionID);
      })
      socket.on('disconnect', () => console.log('discconnected'))

      socket.on('error', (error) => {
        console.error('Socket connection error:', error);
        // Handle error gracefully
      });
      
      socket
        .off(`notification-${workspace.id}`)
        .on(`notification-${workspace.id}`, (data) => {
          console.log('message', data)
          if (data.isMarketplace) {
            this.props.updateInfraBanner(false);

            return marketplaceNotifier(`${intl.formatMessage({ ...messages.module })} 
            ${data.moduleName} ${intl.formatMessage({ ...messages[marketplaceMessages[data.message]] })}`,
              `/${workspace && slugify(workspace.name)}/modules/${data.moduleId
              }`,
              true);
          }

          if(moduleMessages[data.message]) {
            this.getModules();

            return defaultNotifier(intl.formatMessage({ ...messages[moduleMessages[data.message]] }));
          }

          if (data.message === 'dataset_similarity_calculate') {
            this.props.onLoadingParams({ similarity: false })

            this.props.getDatasetListRequest({includeDemoData: this.props.workspace.demoModeEnabled})
            this.props.onLoadingParams({ isSimilarityCompleted: true })
            this.getModules();

            return defaultNotifier(`Similarity calculation ${data.payload.status === 'success' ? 'completed' : 'failed'}.`)
          }

          if (data.message === 'dataset_uniqueness_calculate') {
            this.props.onLoadingParams({ uniqueness: false })

            this.props.getDatasetListRequest({includeDemoData: this.props.workspace.demoModeEnabled})
            this.props.onLoadingParams({ isUniquenessCompleted: true })

            this.getModules();

            return defaultNotifier(`Uniqueness calculation ${data.payload.status === 'success' ? 'completed' : 'failed'}.`)
          }


          if (data.message === 'annotation_jobs_created') {
            this.getAnnotationTask();

            return defaultNotifier(data.payload)
          }

          if (data.message === 'model_training_estimate') {
            return defaultNotifier(data.payload)
          }

          if (data.message === 'incident_created') {
            return defaultNotifier(JSON.parse(data.payload).message)
          }

          if (data.message === 'dataset_activated') {
            // if (isDatasetPage) {
              setTimeout(() => {
                const id = data.payload && data.payload.dataset._id

                this.props.getDatasetDetailRequest({ id });
              }, 3000)
            // }

            return defaultNotifier(data.payload.message)
          }

          if (data.message === 'dataset_clone_error') {
            return defaultNotifier(data.payload.message)
          }

          if (data.message === 'dataset_clone_success') {
            this.props.getDatasetListRequest({ includeDemoData: this.props.workspace.demoModeEnabled })

            return defaultNotifier(data.payload.message)
          }

          if(this.props.addModelImage) {
            this.props.addModelImage(data)
          }
        });
    }

    if ((prevWorkspace && workspace
      && (prevWorkspace.builderInstalled !== workspace.builderInstalled) && workspace.builderInstalled)) {
      clearInterval(this.state.timeInterval);
    }
  }

  async componentDidMount() {
    const { workspace, user, me } = this.props;

    this.props.loggedInUserDataRequest();

    try {
      const { data } = await announcement();

      if (data && data.data && data.data.announcement) {
        this.setState({ announcement: data.data.announcement })
      }
  
      if (workspace && workspace.twoFactorSettingEnabled && !user.twoFactorEnabled) {
        this.setState({ showAuthenticationPage: true });
      }
  
      if (this.props.deviceSizeType === 'tablet' || this.props.deviceSizeType === 'mobile') {
        this.props.updateSidebarDisplay(true);
      }
    } catch(err) {

    }
  }

  setTimeInterval = (ws) => {
    const timeIntervalId = setInterval(() => {
      this.props.fetchWorkspaceRequest({ subDomain: ws || '' });
    }, APP_AUTO_RELOAD);

    this.setState({ timeInterval: timeIntervalId });
  };

  componentWillUnmount() {
    clearInterval(this.state.timeInterval);
    clearInterval(this.state.notificationInterval);
  }

  onModalClose = (isNR) => {
    const { workspace } = this.props;

    this.setState({ isNRInstanceNotRunning: isNR && false })

    return this.props.router.navigate(`/${workspace && slugify(workspace.name)}`);
  }

  renderModal = (isDatasetPage, isAppPage) => {
    const title = this.getTitle(isDatasetPage, isAppPage);
    const buttons = [
      <button className="modal__cancel" key="cancel"
        onClick={() => this.onModalClose(this.state.isNRInstanceNotRunning)}>
        <FormattedMessage {...messages.close} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={title}
        body={<p>We're getting things ready. This might take several minutes.</p>}
        onClose={() => this.onModalClose(this.state.isNRInstanceNotRunning)}
      />
    );
  }

  render() {
    const {
      me,
      loading,
      user, workspace, sidebarStatus, billingDetails, router, notifications,
    } = this.props;
    const { announcement } = this.state;
    const ws = workspace && slugify(workspace.name);

    const pathName = router.location.pathname;

    const isDatasetPage = pathName.includes('/dataset');
    const isAppPage = pathName.includes('/modules') ||
      pathName.includes('/application')

    const isNRorDatasetPage = isDatasetPage || isAppPage

    const createMarkup = () => {
      const { announcement } = this.state;

      return { __html: announcement.message };
    }

    const mobileNavLink = [
      `/${ws}`,
      `/${ws}/dashboard`,
      `/${ws}/profiles`,
      `/${ws}/devices`,
      `/${ws}/applications`,
      `/${ws}/modules`,
      `/${ws}/settings`];

    return (
      <LanguageProvider messages={translationMessages}>
        {
          (pageDoesNotRequireLogin() || (user))
          && (
            <>
              {this.state.showBanner && <Banner
                color={announcement.color}
                onClose={announcement.canClose && this.onCloseBanner}
                desc={<div dangerouslySetInnerHTML={createMarkup()}></div>}
              />
              }
              {workspace && !workspace.builderInstalled && (
                <Banner
                  icon={<InfoCircle />}
                  title="Your infrastructure."
                  color="accent0"
                  desc="We are setting up your infrastructure in the background. The Viso Builder will be ready in a few minutes."
                />
              )}
              {(this.state.isNRInstanceNotRunning &&
                (this.state.isNRInstanceNotRunning === isAppPage)) &&
              this.renderModal(false, isAppPage)}

              <div className='relative'>
                <LeftPanel
                  isLoading={!me || !workspace || loading}
                  hasBanner={this.state.showBanner} />
                <CentralPanel
                  user={user}
                  billingDetails={billingDetails}
                  workspaces={user.workspaces}
                  workspace={workspace}
                  sidebarStatus={sidebarStatus}
                />
                <Notification notifications={notifications} />
                {mobileNavLink.includes(location.pathname) && <MobileNav />}
              </div>
            </>
          )
        }
      </LanguageProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  me: makeSelectMe(),
  sidebarStatus: getSidebarStatus(),
  workspace: makeSelectFetchWorkspace(),
  loading: makeSelectLoading(),
  notifications: getNotifications(),
  billingDetails: getBillingDetails(),
  deviceSizeType: makeSelectDevice(),
  dataset: getLibraryDatasetList(),

});

const withConnect = connect(
  mapStateToProps,
  {
    getDatasetListRequest,
    loggedInUserDataRequest,
    getNotificationList,
    detailRoleRequest,
    onLoadingParams,
    fetchWorkspaceRequest,
    libraryModulesListRequest,
    updateSidebarDisplay,
    updateInfraBanner,
    // updateSocketId,
    addModelImage,
    libraryAnnotationTaskRequest,
    getDatasetDetailRequest,
    getSubscriptionBillingPending,
  },
);

const withReducer = injectReducer({ key: 'global', reducer });
const withSaga = injectSaga({ key: 'global', saga });
const withRoleReducer = injectReducer({ key: 'role', reducer: roleReducer });
const withRoleSaga = injectSaga({ key: 'role', saga: roleSaga });
const withHistoryReducer = injectReducer({ key: 'history', reducer: historyReducer });
const withHistorySaga = injectSaga({ key: 'history', saga: historySaga });
const withUserReducer = injectReducer({ key: 'user', reducer: userReducer });
const withUserSaga = injectSaga({ key: 'user', saga: userSaga });
const withLibraryReducer = injectReducer({ key: 'library', reducer: libraryReducer });
const withLibrarySaga = injectSaga({ key: 'library', saga: librarySaga });
const withNotificationSaga = injectSaga({ key: 'notifications', saga: notificationSaga });
const withNotificationReducer = injectReducer({ key: 'notifications', reducer: notificationReducer });

export default compose(
  withHistoryReducer,
  withHistorySaga,
  withRoleReducer,
  withUserReducer,
  withUserSaga,
  withRoleSaga,
  withReducer,
  withSaga,
  withRouter,
  withLibraryReducer,
  withLibrarySaga,
  withConnect,
  withNotificationSaga,
  withNotificationReducer,
)(App);
